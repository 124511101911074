import type { ThunkDispatch } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import * as AgentAPI from 'services/api/api.agent'
import * as ContactAPI from 'services/api/api.contact'
import * as QueueAPI from 'services/api/api.queue'
import RootState from 'store/state'
import { logout } from 'store/user/user.actions'
import { getMidnight, getTimeRangeString, IStrObj, roundDateDownToNearestMinute } from 'utils'

import { canUserUseFeature } from 'utils'
import { AgentInfo } from '../agents/agents.state'
import { AppFeatures, AppSubFeatures, IFeature } from '../app/app.features'
import { addError } from '../global/global.actions'
import { getRTMQueuesFromState } from '../settings/settings.utils'
import { Queue, Metric as QueueMetric } from '../user/user.state'
import * as MetricsActions from './metrics.reducer'
import {
    AgentStatus,
    IAgentHistoricalData,
    IAgentMetricData,
    IQueueHistoricalData,
    IQueueMetricData,
    QueueMetricDataType,
} from './metrics.state'
import { getQueueMetricsV2 } from './utils'

export const {
    setMetricFilters,
    monitorAgent: clearMonitoringError,
    setMetricsConfig,
    stopMonitorAgent,
} = MetricsActions

const agentsMetricsFields: Array<keyof IAgentHistoricalData> = [
    'handledIn',
    'handledOut',
    'avgHandledTime',
    'avgAcwTime',
]

export function getAgentStatuses(shouldShowLoader: boolean = true) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, MetricsActions.MetricsAction>,
        getState: () => RootState,
    ) => {
        try {
            const { app, auth } = getState()
            const statusesPayloadBase: {
                agentsLoading?: boolean
            } = {}

            //Showing loader is required for the old version of RTM Agents widget. Should be cleaned up after redesign release
            //The loader is shown, because the statuses filtering is applied on FE.
            //To prevent blinking on redesigned version this condition was added.
            if (shouldShowLoader) {
                statusesPayloadBase.agentsLoading = false
                dispatch(MetricsActions.loadingAgentMetrics())
            }
            const agentStatuses = await AgentAPI.getStatuses(app.ID, app.instance!.ID, auth.token!)

            dispatch(
                MetricsActions.getAgentStatuses({
                    ...statusesPayloadBase,
                    agentStatuses,
                }),
            )
        } catch (err) {
            console.log('error getting contacts', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())
        }
    }
}

export function getAgentMetrics() {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, MetricsActions.MetricsAction>,
        getState: () => RootState,
    ) => {
        try {
            const { app, metrics, auth, settings } = getState()

            if (!metrics.agentMetrics) dispatch(MetricsActions.loadingAgentMetrics())
            const { timeRange, routingProfileIDs, hierarchyStructure, statuses, timeZone } =
                metrics.filters
            const from =
                timeRange === 'midnight'
                    ? getMidnight(timeZone)
                    : roundDateDownToNearestMinute(
                          new Date(Date.now() - 1000 * 60 * 60 * timeRange),
                      ).toISOString()
            let historical: IAgentHistoricalData[],
                realtime: AgentInfo[],
                agentStatuses: AgentStatus[]
            const filters: IStrObj = {}
            if (routingProfileIDs) {
                //If none selected just clear
                if (routingProfileIDs.length === 0) {
                    return dispatch(MetricsActions.getAgentMetrics([]))
                }
                filters.routingProfileIDs = routingProfileIDs.join(',')
            }

            if (statuses) {
                filters.statuses = statuses.join(',')
            }

            if (hierarchyStructure) {
                filters.hierarchyStructure = hierarchyStructure.hierarchyStructureIDs.join('/')
            }
            await Promise.all([
                (historical = await ContactAPI.getAgentMetrics(
                    app.ID,
                    app.instance!.ID,
                    auth.token!,
                    from,
                    filters,
                )),
                (realtime = await AgentAPI.getAgents(app.ID, auth.token!, {
                    ...filters,
                    instance_id: app.instance!.ID,
                })),
            ])

            const data: IAgentMetricData[] = realtime.map((agentRT) => {
                const agentHistoric = historical.find(
                    (agentH) => agentRT.agent_id === agentH.agentID,
                )

                const metric: IAgentMetricData = agentHistoric
                    ? { ...agentHistoric, ...agentRT }
                    : {
                          ...agentRT,
                          ...{
                              agentID: agentRT.agent_id,
                              agentName: agentRT.name!,
                              handledIn: 0,
                              handledOut: 0,
                              handled: 0,
                              handledTime: 0,
                              avgHandledTime: 0,
                              acwTime: 0,
                              avgAcwTime: 0,
                              holdTime: 0,
                              avgHoldTime: 0,
                              numberOfHolds: 0,
                              contactTime: 0,
                          },
                      }

                //Is this agent in an update?
                const agentUpdates = getState().metrics.agentUpdates
                const update = agentUpdates?.find((au) => au.agentID === metric.agentID)

                if (update) {
                    metric.routing_profile_id = update.routingProfileID
                    metric.routing_profile = update.routingProfileName
                    if (update.state) metric.state = update.state
                }

                if (agentRT && agentRT.hierarchy_structure && settings?.hierarchyGroups) {
                    const ids = agentRT.hierarchy_structure.split('/')
                    const names = ids.map((id) => {
                        const hierarchyGroup = settings.hierarchyGroups!.find((hg) => hg.Id === id)
                        return hierarchyGroup ? hierarchyGroup.Name : ''
                    })
                    metric.userHierarchy = names.join(' / ')
                }

                return metric
            })
            //To show in the metrics table agents need to be not in offline status or handled a call

            const filteredData = data.filter((metric) => {
                const hasHandledCalls = metric.handledIn > 0 || metric.handledOut > 0
                //If offline and no calls we can ignore
                if (metric.state === 'Offline' && !hasHandledCalls) return false

                //if filtering on routingProfiles they need to have handled calls or have the correct routing profile
                if (
                    routingProfileIDs?.length &&
                    !routingProfileIDs.includes(metric.routing_profile_id!) &&
                    !hasHandledCalls
                ) {
                    return false
                }
                // if filtering on hierarchyStructure they need to have handled calls or hierarchy starts with the selected
                // This is filtering the results based on the hierarchy structure selected in the drop down in the app
                if (hierarchyStructure) {
                    const structure = hierarchyStructure.hierarchyStructureIDs.join('/')
                    if (metric.hierarchy_structure?.indexOf(structure) !== 0 && !hasHandledCalls) {
                        return false
                    }
                }
                // This is filtering based on the users hierarchy group and limiting the results to those agents that are on the users hierarchy or below or all agents if the user has the permission derestrict-agent-access
                const derestrictedAccess =
                    auth?.features
                        ?.find((feature) => feature?.ID === 'realtime-metrics')
                        ?.subfeatures?.includes('derestrict-agent-access') ||
                    auth?.features?.find((feature) => feature?.ID === '*')
                if (!derestrictedAccess) {
                    const usersHierarchyStructure = auth.hierarchyStructure?.replace(/(.*\/)*/, '')
                    if (!usersHierarchyStructure || !metric.hierarchy_structure) return false
                    if (!metric.hierarchy_structure?.split('/').includes(usersHierarchyStructure))
                        return false
                }
                return true
            })

            dispatch(MetricsActions.getAgentMetrics(filteredData))
        } catch (err) {
            console.log(err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())
        }
    }
}

export function getAgentMetricsV2() {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, MetricsActions.MetricsAction>,
        getState: () => RootState,
    ) => {
        try {
            const { app, metrics, auth, settings } = getState()
            const { timeRange, routingProfileIDs, hierarchyStructure, statuses } = metrics.filters

            if (!hierarchyStructure) {
                return dispatch(MetricsActions.getAgentMetrics([]))
            }

            const filters = {
                dataType: getTimeRangeString(timeRange),
                hierarchyStructure: hierarchyStructure.hierarchyStructureIDs.join('/'),
                statuses,
                routingProfileIDs,
            }

            const agentsMetrics = await AgentAPI.getAgentsMetricsV2(
                app.ID,
                app.instance!.ID,
                auth.token!,
                filters,
            )

            const mappedAgentsMetrics = agentsMetrics.map((agentMetrics) => {
                const mappedAgentMetric = {
                    ...agentMetrics,
                }
                mappedAgentMetric.agentID = mappedAgentMetric.agentId!

                if (mappedAgentMetric?.hierarchyStructure && settings?.hierarchyGroups) {
                    const ids = mappedAgentMetric.hierarchyStructure.split('/')
                    const names = ids.map((id: string) => {
                        const hierarchyGroup = settings.hierarchyGroups!.find((hg) => hg.Id === id)
                        return hierarchyGroup ? hierarchyGroup.Name : ''
                    })

                    mappedAgentMetric.userHierarchy = names.join(' / ')
                }

                agentsMetricsFields.forEach((fieldName) => {
                    if (!mappedAgentMetric[fieldName]) {
                        mappedAgentMetric[fieldName] = 0 as never
                    }
                })

                return mappedAgentMetric
            })

            // This is filtering based on the users hierarchy group and limiting the results to those agents that are on the users hierarchy or below or all agents if the user has the permission derestrict-agent-access
            const hasFeature = canUserUseFeature(app.features, auth.features ?? [])
            const derestrictedAccess = hasFeature(AppFeatures.REALTIME_METRICS, AppSubFeatures.DERESTRICT_AGENT_ACCESS)

            const filteredAgentsMetrics = mappedAgentsMetrics.filter(metric => {
                if (!derestrictedAccess) {
                    const usersHierarchyStructure = auth.hierarchyStructure?.replace(/(.*\/)*/, '')

                    if (!usersHierarchyStructure || 
                        !metric.hierarchyStructure?.split('/').includes(usersHierarchyStructure)) {
                        return false
                    }
                }
                
                return true
            })

            dispatch(MetricsActions.getAgentMetrics(filteredAgentsMetrics))
        } catch (err) {
            console.log(err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())
        }
    }
}

interface IRealTimeQueueStats extends Queue {
    data: [QueueMetric]
}

export function getQueueMetrics(isRTMPage: boolean) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, MetricsActions.MetricsAction>,
        getState: () => RootState,
    ) => {
        try {
            const { app, metrics, auth, user } = getState()

            const hasFeature = canUserUseFeature(app.features, auth.features ?? [])

            if (isRTMPage && !metrics.realtimeQueueMetrics)
                dispatch(MetricsActions.loadingQueueMetrics())
            const { timeRange, queueIDs, timeZone } = metrics.filters
            const userQueues = user?.queues ?? []

            const from =
                timeRange === 'midnight'
                    ? getMidnight(timeZone)
                    : roundDateDownToNearestMinute(
                          new Date(Date.now() - 1000 * 60 * 60 * timeRange),
                      ).toISOString()

            const hasUserRTMQueuesRedesigned = hasFeature(
                AppFeatures.REALTIME_DATA_QUEUES_REDESIGNED,
            )

            const realtimeMetricsFeature = app.features.find(
                (feature) => feature.ID === AppFeatures.REALTIME_METRICS,
            ) as IFeature<AppFeatures.REALTIME_METRICS>
            const realtimeDataQueuesRedesignedFeature = app.features.find(
                (feature) => feature.ID === AppFeatures.REALTIME_DATA_QUEUES_REDESIGNED,
            ) as IFeature<AppFeatures.REALTIME_DATA_QUEUES_REDESIGNED>
            const rtmQueuesRedesignedv1_6 =
                hasUserRTMQueuesRedesigned &&
                realtimeDataQueuesRedesignedFeature?.subfeatures?.includes(
                    AppSubFeatures.REALTIME_DATA_QUEUES_REDESIGNED_v1_6,
                )
            const queueStatsFeature = app.features.find(
                (feature) => feature.ID === AppFeatures.QUEUE_STATS,
            ) as IFeature<AppFeatures.QUEUE_STATS>

            const summary: boolean = isRTMPage
                ? realtimeMetricsFeature.config?.showQueueSummary === true
                : false
            const shouldHideHistoricalMetrics: boolean = isRTMPage
                ? false
                : queueStatsFeature.config?.hideHistoricMetrics === true

            let sortedQueueIDs = [] as string[]
            let queuesNamesMap = userQueues.reduce(
                (acc, queue) => {
                    const ID = queue.queueId.split('/queue/')[1]

                    acc[ID] = queue.name

                    return acc
                },
                {} as Record<string, string>,
            )

            const filters: IStrObj = {}
            if (isRTMPage) {
                const [settingsQueues] = getRTMQueuesFromState(getState())
                if (settingsQueues) {
                    queuesNamesMap = settingsQueues.reduce(
                        (acc, queue) => {
                            acc[queue.Id] = queue.Name

                            return acc
                        },
                        {} as Record<string, string>,
                    )
                }

                if (queueIDs) {
                    //If none selected just clear
                    if (queueIDs.length === 0) {
                        return dispatch(MetricsActions.setRealtimeQueueMetrics([]))
                    }
                    sortedQueueIDs = [...queueIDs].sort()
                }
            } else {
                sortedQueueIDs = Object.keys(queuesNamesMap).sort()
            }

            let mergedQueueData = []
            if (!hasUserRTMQueuesRedesigned) {
                if (sortedQueueIDs.length) {
                    filters.queueIDs = sortedQueueIDs.join(',')
                }

                const historicalPromise = shouldHideHistoricalMetrics
                    ? Promise.resolve([])
                    : await ContactAPI.getQueueMetrics(
                          app.ID,
                          app.instance!.ID,
                          auth.token!,
                          from,
                          filters,
                      ).catch((err) => console.error('Error getting queueMetrics:', err))
                const realtimePromise = await QueueAPI.fetchQueueStats(
                    app.ID,
                    auth.token!,
                    app.instance!.ID,
                    filters.queueIDs,
                    summary,
                ).catch((err) => console.error('Error getting queueStats:', err))
                const [historical, realtime] = await Promise.all([
                    historicalPromise,
                    realtimePromise,
                ])

                mergedQueueData = mergeQueueData(realtime, historical as IQueueHistoricalData[])
            } else {
                const historicDataType = getTimeRangeString(timeRange)
                const { metrics, config: metricConfig } = await getQueueMetricsV2({
                    queueIDs: sortedQueueIDs,
                    queuesNamesMap: queuesNamesMap || {},
                    requestParams: {
                        companyID: app.ID,
                        instanceID: app.instance!.ID,
                        token: auth.token!,
                    },
                    dataTypes: [QueueMetricDataType.LIVE, historicDataType!],
                    isRTMPage,
                    tags: user?.tags ?? {},
                })
                mergedQueueData = metrics

                if (rtmQueuesRedesignedv1_6 && metricConfig) {
                    dispatch(MetricsActions.setMetricsConfigV2(metricConfig))
                }
            }

            const filteredData = isRTMPage
                ? (mergedQueueData as IQueueMetricData[])?.filter((q) => q.queueName?.[0] !== '_')
                : mergedQueueData

            if (isRTMPage) {
                dispatch(MetricsActions.setRealtimeQueueMetrics(filteredData as IQueueMetricData[]))
            } else {
                dispatch(MetricsActions.setStatsQueueMetrics(filteredData as IQueueMetricData[]))
            }
        } catch (err) {
            console.log(err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())
        }
    }
}

const mergeQueueData = (
    realtimeStats: IRealTimeQueueStats[],
    historicStats: IQueueHistoricalData[],
) => {
    const queueIDs: string[] = [
        ...realtimeStats.map((rt) => rt.ID ?? ''),
        ...historicStats.map((h) => h.queueID),
    ].filter(Boolean)

    const uniqueQueueIDs = Array.from(new Set(queueIDs))
    if (!uniqueQueueIDs) return []

    return uniqueQueueIDs.map((queueID) => {
        const realtime = realtimeStats.find((rt) => rt.ID === queueID)
        const queueHistoric = historicStats.find((rt) => rt.queueID === queueID)
        return !!queueHistoric
            ? { ...realtime, stats: realtime?.data, ...queueHistoric }
            : {
                  ...realtime,
                  stats: realtime?.data,
                  ...{
                      queueID: realtime?.ID ?? 'Unknown',
                      queueName: realtime?.name ?? 'Unknown',
                      received: 0,
                      answered: 0,
                      rejected: 0,
                      slaStatus: 'default',
                      sla: 0,
                      handled: 0,
                      handledTime: 0,
                      avgHandledTime: 0,
                      acwTime: 0,
                      avgAcwTime: 0,
                      holdTime: 0,
                      avgHoldTime: 0,
                      numberOfHolds: 0,
                      contactTime: 0,
                  },
              }
    })
}

export function editAgentRoutingProfile(
    agentID: string,
    routingProfileID: string,
    routingProfileName: string,
) {
    return async (dispatch: ThunkDispatch<RootState, void, any>, getState: () => RootState) => {
        const { app, auth, metrics } = getState()
        const ams = metrics.agentMetrics!
        try {
            const agentMetrics: IAgentMetricData[] = ams.map((am) => {
                return am.agentID === agentID
                    ? {
                          ...am,
                          routing_profile: routingProfileName,
                          routing_profile_id: routingProfileID,
                      }
                    : am
            })
            // agentPreviousStatus

            dispatch(MetricsActions.getAgentMetrics(agentMetrics))
            dispatch(
                MetricsActions.addAgentUpdate({
                    agentID,
                    routingProfileName,
                    routingProfileID,
                }),
            )

            await AgentAPI.putUser(app.ID, app.instance!.ID, auth.token!, {
                ID: agentID,
                routingProfileID,
            })
        } catch (err) {
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())

            dispatch(addError('There was a problem updating the agents routing profile'))
            dispatch(MetricsActions.getAgentMetrics(ams))
        }
    }
}

export function editAgentStatus(agentID: string, agentStatusId: string, agentStatusName: string) {
    return async (dispatch: ThunkDispatch<RootState, void, any>, getState: () => RootState) => {
        const { app, auth, metrics } = getState()
        const ams = metrics.agentMetrics!
        try {
            const agentMetrics: IAgentMetricData[] = ams.map((am) => {
                return am.agentID === agentID
                    ? {
                          ...am,
                          state: agentStatusName,
                      }
                    : am
            })
            const updatedAgent: IAgentMetricData | undefined = ams.find(
                (am) => am.agentID === agentID,
            )

            dispatch(MetricsActions.getAgentMetrics(agentMetrics))
            dispatch(
                MetricsActions.addAgentUpdate({
                    agentID,
                    routingProfileName: updatedAgent?.routing_profile!,
                    routingProfileID: updatedAgent?.routing_profile_id!,
                    state: agentStatusName,
                }),
            )

            await AgentAPI.putAgentStatus(app.ID, app.instance!.ID, agentID, auth.token!, {
                agentStatusId,
            })
        } catch (err) {
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())

            dispatch(addError('There was a problem updating the agents routing profile'))
            dispatch(MetricsActions.getAgentMetrics(ams))
        }
    }
}

export function monitorAgent(start: boolean, contactID?: string, barge?: boolean) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, MetricsActions.MetricsAction>,
        getState: () => RootState,
    ) => {
        const { app, auth, user } = getState()

        const companyID = app.ID
        const instanceID = app.instance?.ID
        const token = auth.token
        const agentID = user?.agentID

        try {
            if (!instanceID) throw new Error('Instance ID not found')
            if (!agentID) throw new Error('Agent ID not found')
            if (!token) throw new Error('Token not found')

            if (start) {
                await AgentAPI.monitorAgent(companyID, instanceID, agentID, contactID, token, barge)

                dispatch(MetricsActions.monitorAgent({ agentID }))
            } else {
                dispatch(MetricsActions.stopMonitorAgent())
            }
        } catch (err) {
            console.error('Error monitoring the agent: ', err)

            const e = err as AxiosError

            if (agentID) {
                dispatch(
                    MetricsActions.monitorAgent({
                        agentID,
                        error: e.response?.data.error ? e.response.data.error : e.message,
                    }),
                )
            }

            if (e.response?.status === 403) {
                return dispatch(logout())
            }
        }
    }
}
