export type VideoMeetingState = {
    loadingStatus: VideoMeetingLoadingStatus
    meetingID: string | null
    contactID: string | null
}

export enum VideoMeetingLoadingStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface CreateMeetingAction {
    contactID: string
}

export interface CreateMeetingSuccessAction {
    meeting: {
        MeetingID: string
    }
}

export interface CreateMeetingFailedAction {
    message?: string
}
