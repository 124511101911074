import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
    CreateMeetingAction,
    CreateMeetingFailedAction,
    CreateMeetingSuccessAction,
    VideoMeetingLoadingStatus,
    VideoMeetingState,
} from './videoMeeting.state'

const initialState: VideoMeetingState = {
    loadingStatus: VideoMeetingLoadingStatus.IDLE,
    meetingID: null,
    contactID: null,
}

export const videoMeetingSlice = createSlice({
    name: 'videoMeeting',
    initialState,
    reducers: {
        createMeeting(state: VideoMeetingState, action: PayloadAction<CreateMeetingAction>) {
            return {
                ...state,
                contactID: action.payload.contactID,
            }
        },
        createMeetingStart(state: VideoMeetingState) {
            return {
                ...state,
                loadingStatus: VideoMeetingLoadingStatus.LOADING,
            }
        },
        createMeetingSuccess(
            state: VideoMeetingState,
            action: PayloadAction<CreateMeetingSuccessAction>,
        ) {
            return {
                ...state,
                loadingStatus: VideoMeetingLoadingStatus.IDLE,
                meeting: action.payload,
            }
        },
        createMeetingFailed(
            state: VideoMeetingState,
            action: PayloadAction<CreateMeetingFailedAction>,
        ) {
            return {
                ...state,
                loadingStatus: VideoMeetingLoadingStatus.ERROR,
                contactID: null,
                errorMessage: action.payload.message,
            }
        },
    },
    selectors: {
        selectIsMeetingCreated: (state: VideoMeetingState) => {
            return state.meetingID && state.contactID
        },
    },
})

export const { createMeeting, createMeetingStart, createMeetingSuccess, createMeetingFailed } =
    videoMeetingSlice.actions

export const { selectIsMeetingCreated } = videoMeetingSlice.selectors

export type VideoMeetingAction = ReturnType<
    (typeof videoMeetingSlice.actions)[keyof typeof videoMeetingSlice.actions]
>

export default videoMeetingSlice.reducer
