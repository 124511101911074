import { Middleware } from '@reduxjs/toolkit'
import * as videoMeetingAPI from 'services/api/api.videoMeeting'
import { showNotification } from 'store/global/global.reducer'
import RootState from 'store/state'
import {
    createMeeting,
    createMeetingFailed,
    createMeetingStart,
    createMeetingSuccess,
} from 'store/videoMeeting/videoMeeting.reducer'
import { isAnyAction } from 'utils'

const GENERIC_ERROR_MESSAGE = 'An unknown error occured.'

const videoMeetingMiddleware: Middleware<{}, RootState> = (store) => (next) => async (action) => {
    if (!isAnyAction(action)) return

    const {
        app: { ID, instance = { ID: '' } },
        auth: { token = '' },
    } = store.getState()

    switch (action.type) {
        case createMeeting.type: {
            try {
                store.dispatch({ type: createMeetingStart.type })

                const response = await videoMeetingAPI.createMeeting({
                    ...action.payload,
                    companyID: ID,
                    instanceID: instance?.ID,
                    token,
                })

                store.dispatch(createMeetingSuccess(response))
            } catch (error) {
                console.error('Error creating meeting', error)
                store.dispatch(createMeetingFailed({ message: error.message }))
            }
            return next(action)
        }
        case createMeetingFailed.type: {
            store.dispatch({
                type: showNotification.type,
                payload: {
                    type: 'error',
                    header: 'Error creating video meeting',
                    message: action.payload.message ?? GENERIC_ERROR_MESSAGE,
                },
            })
            return next(action)
        }
    }

    return next(action)
}
export default videoMeetingMiddleware
