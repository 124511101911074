import { Button } from '@missionlabs/smartagent-app-components'
import { useDispatch, useSelector } from 'react-redux'
import { selectContact } from 'store/contact/contact.selectors'
import { createMeeting } from 'store/videoMeeting/videoMeeting.reducer'
import styles from './index.module.scss'

export const CreateSession = () => {
    const dispatch = useDispatch()
    const contact = useSelector(selectContact)

    const handleClick = () => {
        if (!contact) return
        dispatch(createMeeting({ contactID: contact?.ID }))
    }

    return (
        <Button
            styling="primary"
            title="Create Meeting"
            elastic
            className={styles.button}
            onClick={handleClick}
            disabled={!contact?.ID}
        >
            <span className={['xsm-mar-right', styles.icon].join(' ')}></span>
            Request Video Sharing
        </Button>
    )
}
