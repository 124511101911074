import { Box } from '@missionlabs/smartagent-app-components'
import { H } from 'react-accessible-headings'
import { useSelector } from 'react-redux'
import { selectIsMeetingCreated } from 'store/videoMeeting/videoMeeting.reducer'
import { CreateSession } from './CreateSession'

import styles from './index.module.scss'

export const VideoSharingRoot = () => {
    const isMeetingCreated = useSelector(selectIsMeetingCreated)

    const elementToRender = isMeetingCreated ? null : <CreateSession />

    return (
        <Box alt collapse boxLabel="Video Sharing" header={<H>Video Sharing</H>}>
            <div className={styles.videoMeetingContainer}>{elementToRender}</div>
        </Box>
    )
}
