import { AgentInfo, AgentInfoV2 } from '../agents/agents.state'
import { Metric as QueueMetric, timeRange } from '../user/user.state'

interface IMetrics {
    handled: number
    handledTime: number
    avgHandledTime: number
    acwTime: number
    avgAcwTime: number
    holdTime: number
    avgHoldTime: number
    numberOfHolds: number
    percentageAbandoned?: string
    contactTime: number
    avgHandledTimeStatus?: string
    percentageRejectedStatus?: string
}

export interface IQueueHistoricalData extends IMetrics {
    queueID: string
    queueName: string
    received: number
    answered: number
    rejected: number
    percentageRejected: number
    sla: number
    answeredInSLA?: number
    slaStatus: string
}

export interface IAgentHistoricalData extends IMetrics {
    agentID: string
    agentName: string
    handledIn: number
    handledOut: number
}
export interface IAgentTeam {
    userHierarchy?: string
}

export interface IAgentMetricData extends IAgentHistoricalData, Partial<AgentInfo>, IAgentTeam {
    status?: string
    duration?: number
}

export type IQueueMetricData = IQueueHistoricalData &
    Partial<{
        stats: QueueMetric[]
        data: QueueMetric[]
    }>

export interface IFilters {
    routingProfileIDs?: string[]
    queueIDs?: string[]
    statuses?: string[]
    hierarchyStructure?: IHierarchyFilter | null
    timeRange: timeRange
    timeZone?: string
}

export interface IHierarchyFilter {
    key: string
    name: string
    hierarchyStructureIDs: string[]
    hierarchyStructureNames: string[]
}

export interface IAgentUpdate {
    agentID: string
    routingProfileName: string
    routingProfileID: string
    state?: string
}

export interface ICallContactAttributes {
    'sa-reason'?: string
    [key: string]: any
}

export interface AgentStatus {
    Id: string
    Arn: string
    Name: string
    Type: string
}

export interface MetricsGroups {
    groupName: string
    groupOrder: number
    metrics: MetricConfigData[]
    metricsType: string
}

export interface MetricConfigData {
    Value?: number
    Status?: string
    clientMetricName: string
    connectConfig: {
        Name: string
        Unit: string
    }
    format: string
    order: string
}

export interface IMonitoringAgentData {
    agentID: string
    error?: string
}

export default interface MetricsState {
    agentMetrics?: IAgentMetricData[] | IAgentMetricDataV2[]
    agentsLoading?: boolean
    statsQueueMetrics?: IQueueMetricData[]
    realtimeQueueMetrics?: IQueueMetricData[]
    metricsGroups?: MetricsGroups[]
    queuesLoading?: boolean
    agentUpdates: IAgentUpdate[]
    filters: IFilters
    monitoringAgent?: IMonitoringAgentData
    agentStatuses?: AgentStatus[]
    config?: IMetricsConfig
}

export interface IQueueMetricsData {
    Value: number
    Status: string
    Metric: {
        Name: string
    }
    ID: string
}
export interface IQueueMetrics {
    ID: string
    data: IQueueMetricsData[]
}

export enum MetricFormat {
    SECONDS = 'SECONDS',
    PERCENTAGE = 'PERCENTAGE',
    COUNT = 'COUNT',
}

export interface IMetricColumn {
    ID: string
    clientConfig: {
        name: string
        tooltip: string
        sort?: boolean
        format: MetricFormat
        unit: MetricFormat
        isHighlighted?: boolean
        page: [string]
    }
    connectConfig: {
        Name: string
        Unit: MetricFormat
    }
    metricsType: 'REALTIME' | 'HISTORICAL'
    targets?: {
        alert: number
        lowerIsBetter: boolean
        target: number
    }
}

export interface IMetricsConfig {
    ID: string
    queueSetup: {
        slaSeconds: number
        slaByAbandoned: boolean
        slaByAnswered: boolean
    }
    timeZone?: string
    columns: IMetricColumn[]
}

export interface IQueueMetricsWithConfig {
    metrics: IQueueMetrics[]
    config: IMetricsConfig
}

export enum QueueMetricDataType {
    LIVE = 'Live',
    'HISTORIC' = 'Historic',
    'HISTORIC-0.5' = 'Historic-0.5',
    'HISTORIC-1' = 'Historic-1',
    'HISTORIC-2' = 'Historic-2',
}

export interface IAgentMetricFiltersV2 {
    hierarchyStructure: string
    dataType?: string
    statuses?: string[]
    routingProfileIDs?: string[]
}

export interface IAgentMetricDataV2 extends IAgentHistoricalData, Partial<AgentInfoV2>, IAgentTeam {
    agentStatus?: string
    duration?: number
}