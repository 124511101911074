import { makeRequest } from '.'

export interface MeetingAPIBaseRequest {
    companyID: string
    instanceID: string
    token: string
}

export interface CreateMeetingAPIRequest extends MeetingAPIBaseRequest {
    contactID: string
}

export interface CreateMeetingAPIResponse {
    meeting: {
        MeetingID: string
    }
    agentAttendee: {}
}

export interface MeetingErrorAPIResponse {
    statusCode: number
    message: string
}

const getBaseURL = ({ companyID, instanceID }: Omit<MeetingAPIBaseRequest, 'token'>) => {
    return `/video-meeting-service/companies/${companyID}/instances/${instanceID}`
}

export const createMeeting = async ({
    contactID,
    token,
    ...baseRequest
}: CreateMeetingAPIRequest): Promise<CreateMeetingAPIResponse> => {
    const response = await makeRequest<CreateMeetingAPIResponse>({
        url: `${getBaseURL(baseRequest)}/meeting/create`,
        method: 'post',
        headers: { 'X-Amz-Security-Token': token },
        data: { contactID },
    })
    return response.data
}
